import queryData from "../../data/csv/dynamicGroup.json";
import sleepStress from "../../data/csv/sleepStress.json";
import womenStd from "../../data/csv/womenStd.json";
import vitaminStd from "../../data/csv/vitaminStd.json";
import heartStd from "../../data/csv/heartStd.json";
import Std from "../../data/csv/stdData.json";
import metalStd from "../../data/csv/metalStd.json";
import lymeStd from "../../data/csv/lymeStd.json";
import menStd from "../../data/csv/menStd.json";
import allergyStd from "../../data/csv/allergyStd.json";
import covidStd from "../../data/csv/covidStd.json";
export const mainQuery = async (group) => {
  if (group) {
    let findData = queryData.find((x) => x.groupVal == group);
    if (findData) {
      return findData.groupName;
    } else {
      return null;
    }
  } else {
    return null;
  }
};
export const sleepStressJson = async (group) => {
  if (group) {
    let findData = sleepStress.find((x) => x.groupVal == group);
    if (findData) {
      return findData.groupName;
    } else {
      return null;
    }
  } else {
    return null;
  }
};
export const womenJson = async (group) => {
  if (group) {
    let findData = womenStd.find((x) => x.groupVal == group);
    if (findData) {
      return findData.groupName;
    } else {
      return null;
    }
  } else {
    return null;
  }
};
export const vitaminJson = async (group) => {
  if (group) {
    let findData = vitaminStd.find((x) => x.groupVal == group);
    if (findData) {
      return findData.groupName;
    } else {
      return null;
    }
  } else {
    return null;
  }
};
export const heartJson = async (group) => {
  if (group) {
    let findData = heartStd.find((x) => x.groupVal == group);
    if (findData) {
      return findData.groupName;
    } else {
      return null;
    }
  } else {
    return null;
  }
};
export const StdJson = async (group) => {
  if (group) {
    let findData = Std.find((x) => x.groupVal == group);
    if (findData) {
      return findData.groupName;
    } else {
      return null;
    }
  } else {
    return null;
  }
};
export const MetalJson = async (group) => {
  if (group) {
    let findData = metalStd.find((x) => x.groupVal == group);
    if (findData) {
      return findData.groupName;
    } else {
      return null;
    }
  } else {
    return null;
  }
};
export const LymeJson = async (group) => {
  if (group) {
    let findData = lymeStd.find((x) => x.groupVal == group);
    if (findData) {
      return findData.groupName;
    } else {
      return null;
    }
  } else {
    return null;
  }
};
export const MenJson = async (group) => {
  if (group) {
    let findData = menStd.find((x) => x.groupVal == group);
    if (findData) {
      return findData.groupName;
    } else {
      return null;
    }
  } else {
    return null;
  }
};
export const AllergyJson = async (group) => {
  if (group) {
    let findData = allergyStd.find((x) => x.groupVal == group);
    if (findData) {
      return findData.groupName;
    } else {
      return null;
    }
  } else {
    return null;
  }
};
export const CovidJson = async (group) => {
  if (group) {
    let findData = covidStd.find((x) => x.groupVal == group);
    if (findData) {
      return findData.groupName;
    } else {
      return null;
    }
  } else {
    return null;
  }
};
